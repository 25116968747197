export interface CredentialsEventPayload {
  ehrCredentials: EhrCredentials;
  credentialsMetadata: CredentialsMetadataPayload;
}

export interface Credentials {
  username: string;
  password: string;
}
export interface EhrCredentials extends Credentials {
  linkedEhrUsername: string;
}

export interface CredentialsMetadataPayload {
  ehrUserLinkingState?: EhrUserLinkingState;
  isEhrPasswordOptional?: boolean;
}

export interface CredentialsMetadata extends CredentialsMetadataPayload {
  shouldShowCredentialsWithError?: boolean;
}

/** Copy of client/runtime/src/apps/login/logic/types.ts, see details there */
export enum EhrUserLinkingState {
  Linked = 'linked',
  Unlinked = 'unlinked',
  NotLinkedYet = 'notLinkedYet',
}
